import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from "moment/moment"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { getEventDateFormatted } from "../../../../utils/gatsby-helpers"

const CardEvent = ({ event, showDescription = false, principal = false, horizontal = false, lang = "en", customClass = null }) => {
    const baseUrl = lang === "en" ? "/events" : `/es/eventos`
    const url = `${baseUrl}/${event.url}`
    const soon = moment().isBefore(event.date_start)
    const comingSoon = {
        'es' : 'Próximamente',
        'en': 'Upcoming'
    }
    const soonMessage = {
        'es' : 'Agendar reunión',
        'en': 'Request a meeting'
    }
    const infoMessage = {
        'es' : 'Más información',
        'en': 'Read more'
    }

    let className = "card card--post"

    if (horizontal) {
        className += " card--post--horizontal"
    }

    if (customClass) {
        className += ` ${customClass}`
    }

    return <div className={className}>
        <div className="card__header">
            {soon && <div className="tag tag--highlight tag--highlight--md">{comingSoon[lang]}</div>}
            <GatsbyImage alt={event.image_preview.alternativeText} image={getImage(event.image_preview.localFile)} />
        </div>

        <div className="card__body">
            <div className="card__body__details">
                <div className="details__date">{getEventDateFormatted(event, lang)}</div>
                <div className="details__location">
                    <i className="icon-location" />
                    {event.location.city}
                </div>
            </div>

            {principal ? <h4><Link to={url}>{event.title}</Link></h4> : <h6><Link to={url}>{event.title}</Link></h6>}

            {showDescription && <div className="card__body__description">
                <ReactMarkdown children={event.seo.meta_description} rehypePlugins={[rehypeRaw]} />
            </div>}

            <span>{soon ? soonMessage[lang] : infoMessage[lang]} <i className="icon-arrow-right" /></span>
        </div>
    </div>
}

export default CardEvent
